import React from 'react';
import { homeObjOne, homeObjFour } from './Data';
import { InfoSection, Pricing } from '../../components';
import Header from '../../components/InfoSection/header';

function Home() {
  return (
    <>
      <Header {...homeObjOne}/>
      <InfoSection {...homeObjFour}/>
    </>
  );
}

export default Home;
