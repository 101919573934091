export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Digital Solutions Agency',
  headline: 'Lead Generation Specialist for Online Businesses',
  description:
    'We help business owners increase their revenue. Our team of unique specialist can help you achieve your business goals',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: require('../../images/svg-111.svg'),
  alt: 'Credit Card',
  start: ''
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Instant Setup',
  headline: 'Extremely quick onboarding process',
  description:
    "Once you've joined, our team of specialist will reach out to you and get you set up in minutes.",
  buttonLabel: 'Learn More',
  imgStart: '',
  img: require('../../images/svg-4.svg'),
  alt: 'Vault',
  start: ''
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Welcome to',
  headline:
    'Gomydev',
  description:
    "Their team is wonderful! I can't believe I didn't start working with them earlier.",
  buttonLabel: 'View Case Study',
  imgStart: 'start',
  img: require('../../images/go.png'),
  alt: 'Vault',
  start: 'true'
};

export const homeObjFour = {
  primary: true,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Secure Database',
  headline: 'All your data is stored on our secure server',
  description:
    'You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.',
  buttonLabel: 'Sign Up Now',
  imgStart: 'start',
  img: require('../../images/svg-5.svg'),
  alt: 'Vault',
  start: 'true'
};
